@use './utils/' as *;
//@use "sass:math";

/*!
.row {
  display: flex;
  flex-wrap: wrap;
  margin: 0 math.div($spacing, 2) * -1;

  @include tablet {
    margin: 0 math.div($mobileSpacing, 2) * -1;
  }
}
*/
// large screen
/*
@for $i from 1 through 12 {
  $v: math.div($i, 12) * 100;

  .col-#{dollari} {
    width: $v * 1%;
  }
}

// medium screen
@include minComputer {
  @for $i from 1 through 12 {
    $v: math.div($i, 12) * 100;

    .col-cm-#{dollari} {
      width: $v * 1%;
    }
  }

  .hide-cm {
    display: none;
  }
}

// tablet screen
@include tablet {
  @for $i from 1 through 12 {
    $v: math.div($i, 12) * 100;

    .col-md-#{dollari} {
      width: $v * 1%;
    }
  }

  .hide-md {
    display: none;
  }
}

// small screen
@include mobile {
  @for $i from 1 through 12 {
    $v: math.div($i, 12) * 100;

    .col-sm-#{dollari} {
      width: $v * 1%;
    }
  }

  .hide-sm {
    display: none;
  }
}
  */
