.login-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px;
}

.login-form-container {
  width: 100%;
  max-width: 400px;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.login-title {
  text-align: center;
  margin-bottom: 20px;
}

.login-form {
  margin-bottom: 15px;
}

.login-button {
  width: 100%;
}
