@use 'scss/utils/variables' as *;

.layoutContainer {
  background: $bodyBg;

  min-height: calc(100vh - ($headerHeight + $footerHeight));
  width: 100%;
}

.containerWithSidebar {
  min-height: calc(100vh - ($headerHeight + $footerHeight + $navbarHeight));
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    'sidebar header'
    'sidebar main';
}
