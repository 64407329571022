@use 'scss/utils/variables' as *;

.navbar {
  height: $navbarHeight;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 0.5rem 0;
  .navbar-brand {
    font-size: 1.5rem;
    font-weight: 700;
    color: #333;
  }
}

.navbar-link {
  margin-right: 1.5rem;
  transition: all 0.3s;
  &:hover {
    color: #888;
  }
  &.active {
    color: #888;
  }
}

.navbar-text {
  margin: 0 0.5rem;
}
