@use './utils/' as *;

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: $fontFamily;
}

html {
  overflow-x: hidden;
  height: 100%;
}

body {
  color: $mainColor;
  font-size: $fontSizeNormal;
  line-height: 1.5;
  background-color: $bodyBg;
  overflow-x: hidden;
  height: 100%;
}

main,
#App {
  min-height: calc(100vh - ($headerHeight + $footerHeight));
}

/*
.error-label {
  font-weight: 700;
  color: $dangerColor;
  font-size: 14px;
}

a {
  text-decoration: none;
  color: unset;
}

.danger-border {
  border: 1px solid $dangerColor;
}

img {
  width: 100%;
}

ul,
li {
  list-style-type: none;
}

.mb {
  margin-bottom: $spacing;

  @include tablet {
    margin-bottom: $mobileSpacing;
  }
}

.bg-black {
  background-color: $mainColor;
  color: $white;
}

.bg-white {
  background-color: $white;
  color: $mainColor;
}

.bg-main {
  background-color: $bodyBg;
}

.title {
  font-size: $fontSizeMedium;
  font-weight: 700;
}

.txt-success {
  color: $green;
}

.txt-danger {
  color: $dangerColor;
}
  */
