@use 'scss/utils/variables' as *;

.mainHeader {
  height: $headerHeight;
  background-color: $headerBg;
  color: $mainColor;
  display: block;
  width: 100%;
}

.logoContainer {
  display: flex;
  align-items: center;
  padding: 0 10%;
}

.logoImage {
  transition: all 0.3s ease-in-out;
  vertical-align: middle;
  &:hover {
    opacity: 0.6;
  }
}
.logoText {
  margin-left: 50px;
  color: $mainBg;
  font-size: 70px;
}
