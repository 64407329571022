.baseContainer {
  width: 80%;
  margin: 0 10%;
  padding: 20px 0;
}

.baseContainerSidebar {
  width: 90%;
  margin: 0 5%;
  padding: 20px 0;
}

.buttonDiv {
  display: flex;
  justify-content: flex-end;
  padding: 20px 0;
  .btn {
    margin: 0 0.3rem;
  }
}
