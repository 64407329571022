$fontFamily: Arial, sans-serif;

$fontSizeNormal: 16px;
$fontSizeMedium: 18px;
$fontSizeLarge: 24px;

$headerHeight: 200px;
$footerHeight: 100px;
$navbarHeight: 70px;

$mainBg: #f8f8f8;
$bodyBg: #f8f8f8;
$headerBg: rgb(12, 14, 38);
$footerBg: rgb(12, 14, 38);

$mainColor: rgb(29, 40, 54);

/*$mainColorSec: #2b3c50;
$grayColor: #c4c4c4;
$dangerColor: #fd523e;
$successColor: #38c4e5;

$purple: #8624db;
$orange: #ff9066;
$white: #fff;
$black: #000;
$green: #14ff00;
$red: #db190c;





$sideBarWidth: 240px;
$sideBarLogoHeight: 171px;
$spacing: 30px;
$mobileSpacing: 24px;
$borderRadius: 30px;

$mobile-width: 450px;
$tablet-width: 670px;
$minComputer-width: 1000px;
*/
